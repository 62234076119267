<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-house"></i>
          ຂໍ້ມູນເຄື່ອງເສຍຫາຍ
          <!--          <span class="countTotal">({{ count }})</span>-->
        </h4>
      </div>
    </div>
    <div class="box">
      <div class="columns" style="display: flex;flex-wrap: wrap">
        <div class="column is-3">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຄົ້ນຫາເລກບິນ :
            </label>
            <input
                style="margin-top: 0.025rem;"
                class="input"
                type="text"
                name="searchItem"
                v-model="searchItem"
                @keyup.enter="FilterBySearch()"
                placeholder="ເລກບິນ.."
            />
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຈາກວັນທິ
            </label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
                v-model="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຫາວັນທິ
            </label>
            <date-picker
                format="DD/MM/YYYY"
                v-model="end_date"
                value-type="YYYY-MM-DD"
                type="date"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>

          </div>
        </div>

        <div class="column is-3">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ສະຖານະ:
            </label>
            <div class="select">
              <select class="input" @change="FetchData" v-model="searchStatus">
                <option value="">ທັງໝົດ</option>
                <option v-for="item in statusAllParcelInjured" :key="item.id" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ປະເພດ:
            </label>
            <div class="select">
              <select class="input" @change="FetchData" v-model="searchType">
                <option value="">ທັງໝົດ</option>
                <option :value="item.id" v-for="item in typeInjuredParcel" :key="item.id">{{ item.name }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="column is-4" style="position: relative; margin-top: 20px">
          <div style="display: flex">
            <vs-button success @click="onPrintExcel" :loading="isBtnLoading">
              <span style="margin-right: 8px; !important;"><i class="fal fa-file-spreadsheet"></i></span>
              Export
            </vs-button>
            <vs-button @click="onCreate">
              <span style="margin-right: 8px"><i class="far fa-plus"></i></span>
              ເພີ່ມຂໍ້ມູນ
            </vs-button>
          </div>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th></vs-th>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ວ.ດ.ປ</vs-th>
            <vs-th>ເລກທີ</vs-th>
            <vs-th>ລະຫັດບິນ</vs-th>
            <vs-th>ສະຖານະການຕິດຕາມ</vs-th>
            <vs-th>ສາຂາຕົ້ນທາງ</vs-th>
            <vs-th>ສາຂາປາຍທາງ</vs-th>
            <vs-th>ປະເພດເຄື່ອງເສຍຫາຍ</vs-th>
            <vs-th>ຈຳນວນເງິນຊົດເຊຍ</vs-th>
            <vs-th>ຈຳນວນເງິນທີ່ນຳງວດ 1</vs-th>
            <vs-th>ວັນທີຈ່າຍງວດ 1</vs-th>
            <vs-th>ຈຳນວນເງິນທີ່ນຳງວດ 2</vs-th>
            <vs-th>ວັນທີຈ່າຍງວດ 2</vs-th>
            <vs-th>ຈຳນວນເງິນທີ່ນຳງວດ 3</vs-th>
            <vs-th>ວັນທີຈ່າຍງວດ 3</vs-th>
            <vs-th>ຈຳນວນເງິນທີ່ຍັງເຫຼືອ</vs-th>
            <vs-th>ລາຄາ CDC</vs-th>
            <vs-th>ລາຄາ COD</vs-th>
            <vs-th>ລາຄາຕົ້ນທາງ</vs-th>
            <vs-th>ຄວາມຮັບຜິດຊອບຕໍ່ຄວາມເສຍຫາຍ</vs-th>
            <vs-th>ສູນຫາຍ ແລະ ເສຍຫາຍ</vs-th>
            <vs-th>ລູກຄ້າ</vs-th>
            <vs-th>ຜູ້ສ້າງ</vs-th>
            <vs-th>ຜູ້ອອກເອກະສານ</vs-th>
            <vs-th>ວັນທີຊຳລະເງິນ</vs-th>
            <vs-th>ສະຖານະບິນ</vs-th>
            <vs-th>ໝາຍເຫດ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :key="index"
                 :data="item"
                 v-for="(item, index) in $vs.getPage(ListItems, page, max)">
            <vs-td style="text-align: right; width: 300px">
              <div class="group-icon">
                <!--                <vs-tooltip>-->
                <vs-button v-if="item.status !== 6" circle icon flat @click="onEdit(item.id,item.status)">
                  <i class="fad fa-box-check"></i>
                </vs-button>
                <!--                  <template #tooltip>-->
                <!--                    ແກ້ໄຂສະຖານະບິນ-->
                <!--                  </template>-->
                <!--                </vs-tooltip>-->

                <!--                <vs-tooltip>-->
                <vs-button v-if="item?.shipment?.shipment_number" circle icon flat success
                           @click="viewInjuredDetail(item.id)">
                  <i class="fad fa-eye"></i>
                </vs-button>
                <!--                  <template #tooltip>-->
                <!--                    ເບິ່ງລາຍລະອຽດ-->
                <!--                  </template>-->
                <!--                </vs-tooltip>-->

                <!--                <vs-tooltip>-->
                <vs-button v-if="item.status === 1" circle icon flat danger @click="onDelete(item.id)">
                  <i class="fas fa-trash-alt"></i>
                </vs-button>
                <!--                  <template #tooltip>-->
                <!--                    ລືບຂໍ້ມູນ-->
                <!--                  </template>-->
                <!--                </vs-tooltip>-->

                <!--                <vs-tooltip>-->
                <vs-button v-if="item.status === 1" circle icon flat dark @click="onUpdateInjured(item.id)">
                  <i class="fas fa-pencil-alt"></i>
                </vs-button>
                <!--                  <template #tooltip>-->
                <!--                    ອັບເດດຂໍ້ມູນພັດສະດຸ-->
                <!--                  </template>-->
                <!--                </vs-tooltip>-->

                <!--                <vs-tooltip ref="addTooltip">-->
                <vs-button v-if="item.status === 4" @click="onUpdateInstallment(item.id)" circle icon flat
                           color="rgb(134, 4, 98)">
                  <i class="fas fa-dollar-sign"></i>
                </vs-button>
                <!--                  <template #tooltip>-->
                <!--                    ອັບເດດຂໍ້ມູນງວດ-->
                <!--                  </template>-->
                <!--                </vs-tooltip>-->


              </div>
            </vs-td>
            <vs-td>
              {{ index + 1 }}
            </vs-td>
            <vs-td>{{ formatDate(item.created_at) }}</vs-td>
            <vs-td>
              <span>{{ item.no ? item.no : '-----' }}</span>
            </vs-td>
            <vs-td>{{ nullSafe(item, t => t.shipment.shipment_number) }}</vs-td>
            <vs-td>
              <div class="defaultStatusClass" :class="getStatusClass(item?.status_name)">
                {{
                  item?.status_name
                }}
              </div>
            </vs-td>
            <vs-td>{{ nullSafe(item, t => t.shipment_detail.start_branch.name) }}
              {{ nullSafe(item, t => t.shipment_detail.start_branch.province.prefix) }}
            </vs-td>
            <vs-td>{{ nullSafe(item, t => t.shipment_detail.end_branch.name) }}
              {{ nullSafe(item, t => t.shipment_detail.end_branch.province.prefix) }}
            </vs-td>
            <vs-td>
              {{
                nullSafe(item, t => t.shipment_detail.parcel.parcel_category.id, null) === 11 ? nullSafe(item, t => t.shipment_detail.parcel.name, '----') : nullSafe(item, t => t.shipment_detail.parcel.parcel_category.name, '----')
              }}
            </vs-td>
            <vs-td>{{ $helpers.numberFormatter(item.amount) }}</vs-td>
            <vs-td>{{ $helpers.numberFormatter(item.first_installment) }}</vs-td>
            <vs-td>{{ item.first_paid ? formatDate(item.first_paid) : '----' }}</vs-td>
            <vs-td>{{ $helpers.numberFormatter(item.second_installment) }}</vs-td>
            <vs-td>{{ item.second_paid ? formatDate(item.second_paid) : '----' }}</vs-td>
            <vs-td>{{ $helpers.numberFormatter(item.third_installment) }}</vs-td>
            <vs-td>{{ item.third_paid ? formatDate(item.third_paid) : '----' }}</vs-td>
            <vs-td>{{
                $helpers.numberFormatter(item.amount - item.first_installment - item.second_installment - item.third_installment)
              }}
            </vs-td>
            <vs-td>{{ $helpers.numberFormatter(item?.cdc_price) }}</vs-td>
            <vs-td>{{ $helpers.numberFormatter(item?.shipment_detail?.total_price) }}</vs-td>
            <vs-td>{{ $helpers.numberFormatter(item?.origin_freight_price) }}</vs-td>
            <vs-td>{{ nullSafe(item, t => t.responsible_party.name, '----') }}</vs-td>
            <vs-td>{{ item.type_name }}</vs-td>
            <vs-td>{{ nullSafe(item, t => t.customer.name, nullSafe(item, t => t.pre_customer.name)) }} | {{nullSafe(item, t => t.customer.tel, nullSafe(item, t => t.pre_customer.tel))}}</vs-td>
            <vs-td>{{ nullSafe(item, t => t.created_by.name, '----') }}</vs-td>
            <vs-td>{{ nullSafe(item, t => t.success_by.name, '----') }}</vs-td>
            <vs-td>{{ item.paid_date ? formatDate(item.paid_date) : '----' }}</vs-td>
            <vs-td>{{ nullSafe(item, t => t.shipment_detail.tracking, '----') }}</vs-td>
            <vs-td>{{ nullSafe(item, t => t.remark, '----') }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>

    <ModalAdd>
      <template v-slot="{ close }">
        <CheckShipment @close="close" @success="onCreateInjuredParcel"/>
      </template>
    </ModalAdd>
    <ModalEdit>
      <template v-slot="{ close }">
        <UpdateStatusInjuredParcel :id="id" :defaultStatusInjured="defaultStatusInjured"
                                   :statusInjuredParcel="statusInjuredParcel" @close="close" @success="FetchData"/>
      </template>
    </ModalEdit>
    <ModalDelete>
      <template v-slot="{ close }">
        <DeleteInjuredParcel @close="close" :itemId="itemId" @success="FetchData"/>
      </template>
    </ModalDelete>


  </div>
</template>
<script>
import Loading from "@/views/Loading";
import pagination from "@/Plugins/Paginations/CusorPagination";
import DatePicker from "vue2-datepicker";
import * as helper from "@/Helpers/GetValue";
import UpdateStatusInjuredParcel from "@views/SuperAdmin/InjuredParcel/Modal/UpdateStatusInjuredParcel.vue";
import CheckShipment from "@/views/SuperAdmin/InjuredParcel/Modal/CheckShipment.vue"
import moment from "moment";
import helpers from "../../../Helpers";
import router from "@/router";
import DeleteInjuredParcel from "@views/SuperAdmin/InjuredParcel/Modal/Delete.vue";


export default {
  computed: {
    helpers() {
      return helpers
    }
  },
  components: {
    DeleteInjuredParcel,
    Loading,
    pagination,
    DatePicker,
    UpdateStatusInjuredParcel,
    CheckShipment,
  },


  data: () => ({
    page: 1,
    max: 10,
    isLoading: true,
    isBtnLoading: false,

    ListItems: [],
    statusInjuredParcel: [],
    typeInjuredParcel: [],
    statusAllParcelInjured: [],
    defaultStatusInjured: null,
    id: null,
    // for delete
    itemId: null,
    start_date: localStorage.getItem("startDateInjured") || moment(new Date()).format('YYYY-MM-DD'),
    end_date: localStorage.getItem("endDateInjured") || moment(new Date()).format('YYYY-MM-DD'),


    searchStatus: "",
    searchType: "",
    searchItem: "",

    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
  }),
  watch: {
    'searchItem': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
    'type': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
    'status': function (value) {
      if (value === '') {
        this.FetchData();
      }
    },
    'start_date': function (value) {
      if (value) {
        this.FetchData();
      }
    },
    'end_date': function (value) {
      if (value) {
        this.FetchData();
      }
    },
  },

  methods: {
    nullSafe(instance, func, defaultValue = '----') {
      try {
        return func(instance) ?? defaultValue;
      } catch (_) {
        return defaultValue;
      }
    },
    viewInjuredDetail(id) {
      router.push({name: 'injuredParcel.detail', params: {id: id}})
    },

    onUpdateInjured(id) {
      router.push({
        name: "update.injured",
        params: {
          id: id
        }
      })
    },

    onCreateInjuredParcel(shipment_id) {
      router.push({
        name: "injuredParcel.create",
        params: {
          id: shipment_id
        }
      })
    },
    getStatusClass(status) {
      if (status === null) {
        return null
      }
      if (status === "contacted" || status === "ຕິດຕໍ່ກັບພະນັກງານ") {
        return "contacted-class";
      } else if (status === "pending" || status === "ລໍຖ້າດຳເນີນການ") {
        return "pending-class";
      } else if (status === "accepted" || status === "ຮັບເລື່ອງແລ້ວ") {
        return "accepted-class";
      } else if (status === "approved" || status === "ບັນຫາຖືກຍອມຮັບ") {
        return "approved-class";
      } else if (status === "rejected" || status === "ບັນຫາຖືກປະຕິເສດ") {
        return "rejected-class";
      } else {
        return "resolved-class";
      }
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY")
    },

    onCreate() {
      this.$store.commit("modalAdd_State", true);
    },
    onDelete(id) {
      this.itemId = id;
      this.$store.commit("modalDelete_State", true);
    },
    async onEdit(id, status) {
      this.id = id;
      this.defaultStatusInjured = status;

      try {
        await this.fetchInjuredParcelInfo(status);
        await this.OnUpdateInjuredParcelStatus();
      } catch (error) {
        console.error(error);
      }
    },
    async fetchInjuredParcelInfo(status) {
      // eslint-disable-next-line no-useless-catch
      try {
        const config = {
          headers: {
            Locale: "lo"
          },
        };
        const response = await this.$http.get(`injured-parcel-info?type=statuses&status=${status}`, config);
        if (response) {
          this.statusInjuredParcel = response.data.data;
        }
      } catch (error) {
        throw error;
      }
    },
    onUpdateInstallment(id) {
      router.push({
        name: "update.installment",
        params: {
          id: id,
        }
      }).catch(() => {
      })

    },
    //fetch Type Injured Parcel
    async fetchTypeInjuredParcelInfo() {
      // eslint-disable-next-line no-useless-catch
      try {
        const config = {
          headers: {
            Locale: "lo"
          },
        };
        const response = await this.$http.get(`injured-parcel-info?type=types`, config);
        if (response) {
          this.typeInjuredParcel = response.data.data;
        }
      } catch (error) {
        throw error;
      }
    },
    async fetchStatusInjuredParcelInfo() {
      // eslint-disable-next-line no-useless-catch
      try {
        const config = {
          headers: {
            Locale: "lo"
          },
        };
        const response = await this.$http.get(`injured-parcel-info?type=statuses`, config);
        if (response) {
          this.statusAllParcelInjured = response.data.data;
        }
      } catch (error) {
        throw error;
      }
    },
    async OnUpdateInjuredParcelStatus() {
      this.$store.commit("modalEdit_State", true)
    },
    FetchData() {
      this.isLoading = true;
      this.fetchItem().then((res) => {
        if (!res.data.error) {
          setTimeout(() => {
            this.ListItems = res.data.data.data.map(item => ({
              origin_freight_price: item?.shipment_detail?.shipment_pay_type === 'origin_freight_fees_cod' ? item?.shipment_detail?.total_freight ?? '----' : '----',
              cdc_price: item?.shipment_detail?.shipment_pay_type !== 'origin_freight_fees_cod' ? item?.shipment_detail?.total_freight ?? '----' : '----',
              ...item,
            }))
            this.pagination = res.data.data ?? {};
          }, 300);
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    fetchItem(options = {}) {
      localStorage.setItem("startDateInjured", this.start_date);
      localStorage.setItem("endDateInjured", this.end_date);
      const {current_page, per_page} = options;
      return this.$http.get('injured-parcel', {
        headers: {
          Locale: 'lo'
        },
        params: {
          start_date: this.start_date,
          end_date: this.end_date,
          status: this.searchStatus,
          search: this.searchItem,
          type: this.searchType,
          cursor: current_page ?? this.pagination.cursor,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },
    onPrintExcel() {
      this.isBtnLoading = true;
      const item = {
        start_date: this.start_date,
        end_date: this.end_date,
        methodItem: this.$http
      }
      this.$store.dispatch('exportInjuredParcel', item)
          .catch((e) => {
            console.log(e)
          }).finally(() => {
        this.isBtnLoading = false;
      })
    },

  },
  created() {
    this.FetchData();
    this.fetchTypeInjuredParcelInfo();
    this.fetchStatusInjuredParcelInfo();
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.defaultStatusClass {
  background: red;
  padding: 2px 8px;
  width: max-content;
  color: #ffffff;
  text-align: center;
  border-radius: 30px;
  font-size: 14px;
  letter-spacing: 1px;
}

.contacted-class {
  background: #12CBC4;
}

.pending-class {
  background: rgba(249, 202, 36, 0.6);
}

.accepted-class {
  background: #0652DD;
}

.rejected-class {
  background: #e21312;
}

.approved-class {
  background: #A3CB38;
}

.resolved-class {
  background: #009432;
}

</style>
