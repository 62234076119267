<template>
  <div class="container">
    <div class="columns box-title is-12">
      <div class="column is-6">
        <h4 class="has-text-left">
          <i class="fal fa-house"></i>
          ຂໍ້ມູນບິນຈາກຄູ່ຮ່ວມທຸລະກິດ
        </h4>
      </div>
    </div>
    <div class="box">
      <div class="columns" style="display: flex;flex-wrap: wrap">
        <div class="column is-3">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຈາກວັນທິ
            </label>
            <date-picker
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD"
                type="date"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
                v-model="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຫາວັນທິ
            </label>
            <date-picker
                format="DD/MM/YYYY"
                v-model="end_date"
                value-type="YYYY-MM-DD"
                type="date"
                v-validate="'required'"
                :clearable="false"
                name="valid_date"
            ></date-picker>

          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ເລືອກຄູ່ຮ່ວມທຸລະກິດ:
            </label>
            <div class="select">
              <select class="input" @change="FetchData" v-model="vendor_id">
                <option value="">ທັງໝົດ</option>
                <option v-for="item in listVendor" :key="item.id" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="control is-flex" style="margin-top: 30px;">
            <label for="">
              ເບິ່ງພາບລວມ
            </label>
            <vs-checkbox style="margin-left: 4px" v-model="overview"/>
          </div>
        </div>
        <div style="position: absolute;right: 10px;top: 20px">
          <vs-button success @click="exportToXLSX(isExportTable)" :loading="btnLoading">
            Export Excel
          </vs-button>
        </div>
      </div>
    </div>
    <div class="center fit-width">
      <vs-table ref="table">
        <template #thead>
          <vs-tr>
            <vs-th>ລຳດັບ</vs-th>
            <vs-th>ຊື່ຄູ່ຮ່ວມທຸລະກິດ</vs-th>
            <vs-th v-if="!overview">ຊື່ຮ້ານຄ້າ</vs-th>
            <vs-th>ຈຳນວນບິນ</vs-th>
            <vs-th>ຄ່າຂົນສົ່ງທັງໝົດ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :key="index"
                 :data="item"
                 v-for="(item, index) in $vs.getPage(ListItemsBillVendor, page, max)">
            <vs-td>
              {{ index + 1 }}
            </vs-td>
            <vs-td>
              <div style="display: flex">
                <vs-avatar size="small" v-if="item.vendor_id == '233171'">
                  <img :src="require('@/assets/Kaiymaun.png')" alt="">
                </vs-avatar>
                <vs-avatar color="#dee2e6" size="small" v-else>

                </vs-avatar>
                <div style="display: flex;justify-content: center;align-items: center;margin-left: 4px">
                  {{ item.vendor_name }}
                </div>
              </div>
            </vs-td>
            <vs-td v-if="!overview">
              {{ item.name }} {{ item.surname }}
            </vs-td>
            <vs-td>
              <div class="bill-chip">
                {{ $helpers.numberFormatter(item.qty) }} ບິນ
              </div>
            </vs-td>
            <vs-td>
              <div style="font-weight: 900">
                {{ $helpers.numberFormatter(item.amount) }} ກີບ
              </div>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import Loading from "@/views/Loading";
import pagination from "@/Plugins/Paginations/Pagination.vue";
import DatePicker from "vue2-datepicker";
import * as helper from "@/Helpers/GetValue";
import moment from "moment";
import helpers from "../../../Helpers";
import XLSX from "xlsx";

export default {
  computed: {
    helpers() {
      return helpers
    }
  },

  components: {
    Loading,
    pagination,
    DatePicker,
  },


  data: () => ({
    // Pagination
    listVendor: [],
    ListItemsBillVendor: [],
    ListItemsBillVendorExport: [],
    isExportTable: false,
    btnLoading: false,
    vendor_id: '',
    overview: false,
    page: 1,
    max: 10,
    isLoading: true,
    isBtnLoading: false,
    start_date: localStorage.getItem("start_date") || moment(new Date()).format('YYYY-MM-DD'),
    end_date: localStorage.getItem("end_date") || moment(new Date()).format('YYYY-MM-DD'),

    // Pagination
    count: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
  }),
  watch: {
    'vendor_id': function (value) {
      if (value) {
        this.FetchData();
      }
    },
    'start_date': function (value) {
      if (value === true) {
        this.FetchData(this.isExportTable === true);
      } else {
        this.FetchData();
      }
    },
    'end_date': function (value) {
      if (value === true) {
        this.FetchData(this.isExportTable === true);
      } else {
        this.FetchData();
      }
    },
    'overview': function (value) {
      if (value === true) {
        this.FetchData(this.isExportTable === true);
      }
      if (value === false) {
        this.FetchData();
      }
      this.FetchData();
    }
  },

  methods: {
    nullSafe(instance, func) {
      try {
        return func(instance);
      } catch (_) {
        return undefined
      }
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY")
    },
    async fetchVendorList() {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await this.$http.get(`list-vendor`);
        if (response) {
          this.listVendor = response.data.data;
        }
      } catch (error) {
        throw error;
      }
    },

    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },

    async exportToXLSX(isExportTable) {
      this.btnLoading = true;
      if (!isExportTable) {
        await this.FetchData(!isExportTable);
        this.btnLoading = false;
      }
      let expData = []
      for (const item of this.ListItemsBillVendorExport) {
        expData.push(item)
      }
      const ws = XLSX.utils.json_to_sheet(
          expData.map(item => {
            const rowData = {
              'ຊື່ຄູ່ຮ່ວມທຸລະກິດ': item.vendor_name,
              'ຈຳນວນບິນ': item.qty,
              'ຄ່າຂົນສົ່ງທັງໝົດ': item.amount,
              'ແຕ່ວັນທິ': this.formatDate(this.start_date),
              'ຫາວັນທີ': this.formatDate(this.end_date)
            };

            // Check if item.name and item.surname are defined and not empty
            if (item.name && item.surname && item.name.trim() !== '' && item.surname.trim() !== '') {
              rowData['ຊື່ຮ້ານ'] = item.name + item.surname;
            }

            return rowData;
          }),
          {
            header: [
              'ຊື່ຄູ່ຮ່ວມທຸລະກິດ',
              ...expData.some(item => item.name && item.surname && item.name.trim() !== '' && item.surname.trim() !== '')
                  ? ['ຊື່ຮ້ານ'] // Include 'ຊື່ຮ້ານ' in the header if any item has name and surname
                  : [], // Exclude 'ຊື່ຮ້ານ' from the header if no item has name and surname
              'ຈຳນວນບິນ',
              'ຄ່າຂົນສົ່ງທັງໝົດ',
              'ແຕ່ວັນທິ',
              'ຫາວັນທີ'
            ]
          }
      );

      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      const fileName = 'vendor_bill.xlsx'
      XLSX.writeFile(wb, fileName)
    },

    async FetchData(isExport) {
      try {
        this.isLoading = true;
        const res = await this.fetchItem(isExport);
        if (!res.data.error) {
          this.isLoading = false;
          if (res.config.params.print === 1) {
            this.ListItemsBillVendorExport = res.data.data;
          } else {
            this.ListItemsBillVendor = res.data.data.data.data;
            this.pagination = res.data.data.data;
          }
        }
      } catch (e) {
        this.isLoading = false;
      }
    },
    async fetchItem(isExport, options = {}) {
      localStorage.setItem("start_date", this.start_date);
      localStorage.setItem("end_date", this.end_date);
      const {current_page, per_page} = options;
      const includeOverview = this.overview === true;
      const params = {
        start_date: this.start_date,
        end_date: this.end_date,
        vendor_id: this.vendor_id,
        page: current_page ?? this.pagination.current_page,
        per_page: per_page ?? this.limit_page,
      };
      const paramsExport = {
        start_date: this.start_date,
        end_date: this.end_date,
        vendor_id: this.vendor_id,
        print: 1,
      };
      if (includeOverview) {
        params.overview = 1;
        paramsExport.overview = 1;
      }
      return this.$http.get('list-vendor-shipment', {
        params: isExport === true ? paramsExport : params,
      })
    },
  },
  created() {
    this.FetchData();
    this.fetchVendorList();
  },
};
</script>
<style lang="scss" scoped>
.bill-chip {
  background: #e63946;
  width: 100px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 900;
}

.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.defaultStatusClass {
  background: red;
  padding: 2px 8px;
  width: max-content;
  color: #ffffff;
  text-align: center;
  border-radius: 30px;
  font-size: 14px;
  letter-spacing: 1px;
}

.contacted-class {
  background: #12CBC4;
}

.pending-class {
  background: rgba(249, 202, 36, 0.6);
}

.accepted-class {
  background: #0652DD;
}

.rejected-class {
  background: #e21312;
}

.approved-class {
  background: #A3CB38;
}

.resolved-class {
  background: #009432;
}

</style>
