<script>
import {Validator} from "vee-validate";

const dict = {
  custom: {
    shipment_number: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
    },
  },
};
Validator.localize("en", dict);
export default {
  data() {
    return {
      shipment_number: ""
    }
  },
  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.SaveItem();
        }
      });
    },
    SaveItem() {
      this.isBtnLoading = true;
      this.$http.get(`check-exist/${this.shipment_number}`).then((res) => {
        if (res.data.code === 200) {
          this.isBtnLoading = false;
          this.$emit('close');
          this.$emit('success', res.data.data.id);
        }
      }).catch((error) => {
        this.isBtnLoading = false;
        if (error.response.status === 422) {
          const obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        if (error.response.status === 400) {
          const errorsMessage = error.response.data.message
          this.$notification.OpenNotification_Error('top-right', 'danger', 3000, errorsMessage);
        }
        if (error.response.status === 404) {
          const errorsMessage = error.response.data.message
          this.$notification.OpenNotification_Error('top-right', 'danger', 3000, errorsMessage);
        }
      });
    }
  },
}
</script>

<template>
  <div class="container">
    <div class="form-system-route">
      <div class="box-image">
        <div class="image-route">
          <img :src="require('@/assets/checkBill.png')" alt="">
        </div>
      </div>
      <div class="field column">
        <div style="margin-top: 12px" class="control">
          <label style="margin-right: 10px; margin-top: 2px;" class="label">
            ເລກບິນ <span class="text-danger">* {{ errors.first('shipment_number') }}</span>
          </label>
          <input
              v-model="shipment_number"
              class="input"
              type="text"
              name="shipment_number"
              v-validate="'required|max:191'"
              placeholder="ກະລຸນາປ້ອນກ່ອນ..."
          />
        </div>
        <div class="con-btns">
          <vs-button
              @click="ValidateForm"
              ref="button"
              flat
              class="form-btn-loading"
          >ບັນທຶກຂໍ້ມູນ
          </vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.form-system-route {
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;

  .box-image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .image-route {
      width: 120px;
      height: 120px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

}
</style>
