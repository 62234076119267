<template>
  <div class="container">
    <div class="form-system-route">
      <div class="box-image">
        <div class="image-route">
          <img :src="require('@/assets/container.png')" alt="">
        </div>
      </div>
      <div class="field column">
        <div style="margin-top: 12px" class="control">
          <label style="margin-right: 10px; margin-top: 2px;" class="label">
            ເລືອກສະຖານະເພີ່ມອັບເດດ <span class="text-danger">* {{ errors.first("status") }}</span>
            <span class="text-danger">
              {{ server_errors.status }}
            </span>
          </label>

          <div class="select">
            <select class="input" v-model="value.status" name="status" v-validate="'required'">
              <option value=""></option>
              <option :value="item.id" :key="item.id" v-for="item in statusInjuredParcel">{{ item.name }}</option>
            </select>
          </div>
        </div>

        <div>
          <div style="margin-top: 12px" class="control" v-if="isShowConditionSix">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ເລກທີ <span class="text-danger"></span>
            </label>
            <span class="text-danger" style="color: red">
              {{ server_errors.no }}
            </span>
            <input
                v-model="value.no"
                class="input"
                type="text"
                placeholder="ກະລຸນາປ້ອນກ່ອນ..."
            />
          </div>
          <div style="margin-top: 12px" class="control" v-if="isShowConditionFour">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຈຳນວນເງິນຊົດເຊີຍ
            </label>
            <input
                class="input"
                type="text"
                v-model="value.amount"
                placeholder="ກະລຸນາປ້ອນກ່ອນ..."
            />
          </div>
          <div style="margin-top: 12px" class="control"
               v-if="isShowConditionSix === true">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຄວາມຮັບຜິດຊອບຕໍ່ຄ່າເສຍຫາຍ
            </label>
            <input
                v-model="value.responsible"
                class="input"
                type="text"
                placeholder="ກະລຸນາປ້ອນກ່ອນ..."
            />
          </div>
        </div>
        <!--        if status === 5 or 6-->
        <div style="margin-top: 12px" class="control"
             v-if="isShowRemark">
          <label style="margin-right: 10px; margin-top: 2px;" class="label">
            ໝາຍເຫດ <span class="text-danger"></span>
          </label>
          <span class="text-danger" style="color: red">
              {{ server_errors.remark }}
            </span>
          <input
              v-model="value.remark"
              class="input"
              type="text"
              placeholder="ກະລຸນາປ້ອນກ່ອນ..."
          />
        </div>
        <div class="con-btns">
          <vs-button
              @click="ValidateForm"
              ref="button"
              flat
              :loading="isBtnLoading"
              class="form-btn-loading"
          >ບັນທຶກຂໍ້ມູນ
          </vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Validator} from "vee-validate";

const dict = {
  custom: {
    status: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
    },
    remark: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
    }
  },
};
Validator.localize("en", dict);
export default {
  name: "Manage",
  props: ['statusInjuredParcel', 'id', 'defaultStatusInjured'],
  data() {
    return {
      isBtnLoading: false,
      server_errors: {
        status: "",
        remark: "",
        responsible: "",
        amount: "",
        no: ""
      },
      value: {
        status: "",
        responsible: "",
        amount: "",
        remark: "",
        no: "",
      },
      installment: [
        {
          id: 1,
          name: "ງວດທີ່ 1"
        },
        {
          id: 2,
          name: "ງວດທີ່ 2"
        },
        {
          id: 3,
          name: "ງວດທີ່ 3"
        }
      ],
      isShowRemark: false,
      isShowConditionFour: false,
      isShowConditionSix: false,
    }
  },
  watch: {
    'value.status': function (newStatus) {
      if (newStatus == 5) {
        this.isShowRemark = true;
        this.isShowConditionFour = false;
      } else if (newStatus == 6) {
        this.isShowRemark = true;
        this.isShowConditionSix = true;
        this.isShowConditionFour = false;
        this.is
      } else if (newStatus == 4) {
        this.isShowConditionFour = true;
        this.isShowRemark = false;
      } else {
        this.isShowRemark = false;
        this.isShowConditionFour = false;
        this.isShowConditionSix = false;
      }
      this.server_errors.status = "";
    },
    "value.remark": function () {
      this.server_errors.remark = "";
    },
    "value.responsible": function () {
      this.server_errors.responsible = "";
    },
    "value.amount": function () {
      this.server_errors.amount = "";
    },
    "value.no": function () {
      this.server_errors.no = "";
    },
  },
  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.SaveItem();
        }
      });
    },
    SaveItem() {
      this.isBtnLoading = true;
      this.$http.put(`admin/update-injured-parcel/${this.id}`, this.value, {
        headers: {
          Locale: 'lo'
        },
      }).then((res) => {
        if (res) {
          this.isBtnLoading = false;
          this.$emit('close');
          this.$emit('success');
        }
      }).catch((error) => {
        this.isBtnLoading = false;
        if (error.response.status === 422) {
          const obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        if (error.response.status === 400) {
          const errorsMessage = error.response.data.message
          this.$notification.OpenNotification_Error('top-right', 'danger', 3000, errorsMessage);
        }
      });
    }
  },
  created() {
    if (this.defaultStatusInjured === 5) {
      this.isShowRemark = true;
      this.isShowConditionFour = false;
      this.value.status = this.defaultStatusInjured
    }
    if (this.defaultStatusInjured === 4) {
      this.isShowRemark = false;
      this.isShowConditionFour = true;
      this.value.status = this.defaultStatusInjured
    }
  }
}
</script>

<style scoped lang="scss">
.form-system-route {
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;

  .box-image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .image-route {
      width: 120px;
      height: 120px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

}
</style>
